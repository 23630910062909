import React, { useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import InputMask from 'react-input-mask'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// CSS, SCSS
import '../styles/solicite-sua-coleta.scss'

// components
// import Pagina from '../../components/Pagina/index'

// Auth
import { confirmSignUp, resendConfirmationCode, isBrowser } from '../../services/auth'

const ConfirmaConta = ({ location }) => {
  const intl = useIntl()
  const [formState, setFormState] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessageVisibility, setSuccessMessageVisibility] = useState('')
  const data = useStaticQuery(
    graphql`{
  pagina: contentfulPagina(path: {eq: "/criar-conta/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  img: file(relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/fuso.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 700, height: 840, layout: CONSTRAINED)
    }
  }
}
`)

  const handleConfirmAccount = () => {
    confirmSignUp(location.state.email, formState)
      .then(res => {
        if (res === 200) {
          setErrorMessage(false)
          setSuccessMessageVisibility(
            <>{parse(intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.confirm_account.success_message' }))}</>
          )

          setTimeout(() => {
            let url = isBrowser() && localStorage.getItem('originPath')
            url = url === null ? '/coleta-de-oleo-lubrificante-usado/' : url
            navigate(url)
          }, 3000)
        } else {
          setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.confirm_account.error_message' })}</>)
        }
      })
  }

  const resendCode = () => {
    resendConfirmationCode(location.state.email)
      .then(res => {
        if (res === 200) {
          setSuccessMessageVisibility(<>{intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.confirm_account.success_message' })}</>)
        } else {
          setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.confirm_account.error_message' })}</>)
        }
      })
  }

  return <>
    {/* <Pagina pagina={data.pagina} /> */}
    <section className="container-fluid full m-0">
      <div className="row">
        <div className="col-lg-4 p-0">
          <GatsbyImage
            image={data.img.childImageSharp.gatsbyImageData}
            alt=""
            className="w-100 h-100" />
        </div>
        <div className="col-lg-8 bg-lwart-lightblue2 p-0">
          <div className="container" style={{ padding: '35px' }}>
            <p className="text-white text-uppercase font-Spinnaker font-size-125">{intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.section_confirmar_conta.p1' })}</p>
            <p className="text-white text-uppercase font-Spinnaker font-size-08">
              {parse(intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.section_confirmar_conta.p2' }))}
            </p>
            <p className="font-Spinnaker font-size-2 text-lwart-green3"><b className="font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.section_confirmar_conta.p3' })}</b></p>
            <div className="form-coleta p-0 w-100 mw-100">
              <div className="row">
                <div className="col-lg-6">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.section_confirmar_conta.label1' })}
                    <InputMask
                      mask="999999"
                      className="mb-4 w-100"
                      name="code"
                      type="text"
                      onChange={(event) => setFormState(event.target.value)}
                    />
                  </label>
                </div>
                <div className="col-lg-12 p-0">
                  {
                    !!errorMessage && (
                      <div className="d-flex bg-lwart-darkblue align-items-center py-2 px-4 my-3 rounded">
                        <p className="bg-danger shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                          style={{ width: '25px', height: '25px' }} >!</p>
                        <p className="text-center text-white mb-0 font-weight-bold w-75">
                          {errorMessage}
                        </p>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="rounded border-0 text-white btn-entrar w-100 py-3 mb-3 font-Spinnaker"
                    onClick={() => handleConfirmAccount()}
                  >
                    {intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.section_confirmar_conta.btn1' })}
                  </button>
                  <button className="btn btn-link text-white" onClick={() => resendCode()}>
                    {intl.formatMessage({ id: 'paginas.area_cliente.confirmar_conta.section_confirmar_conta.btn1' })}
                  </button>
                </div>
                <div className="col-md-12">
                  {
                    !!successMessageVisibility && (
                      <div className="d-flex align-items-center justify-content-center bg-lwart-green rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                          {successMessageVisibility}
                        </p>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default ConfirmaConta
